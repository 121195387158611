import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/index'

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

 import './styles/index.scss';
// import './styles/element-ui.scss';
// import './styles/variables.scss';
import "./permission"; // permission control

Vue.prototype.$IMG_URL='https://kt.breekj.top';

Vue.use(ElementUI);
Vue.config.productionTip = false

import 'ant-design-vue/dist/antd.css'; // 引入样式文件  

new Vue({
  router,
  store,
  render: function (h) { return h(App) }
}).$mount('#app')
