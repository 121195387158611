
<template>
  <div>
    <div class="BOX">
      <div class="headbox">
        <div class="shopitembox">
          <div class="header-box">
            <div class="tzggtitle"> <img class="tzggicon" src="@/assets/hld.png" alt="" /> 素材库 </div>
            <div class="select-box">
              <el-select v-model="formData.group_id" placeholder="选择类型" @change="formChange">
                <el-option
                  v-for="item in tabList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                  clearable="true"
                  :disabled="item.disabled">
                </el-option>
              </el-select>
              <div style="width: 20px;"></div>
              <el-select v-model="formData.group_id" placeholder="选择分组" @change="formChange">
                <el-option
                  v-for="item in groupList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                  clearable="true"
                  :disabled="item.disabled">
                </el-option>
              </el-select>

              <el-input class="searchkey" v-model="formData.keyword" placeholder="请输入关键词" :clearable="true"></el-input>
              <el-button class="searchBtn" @click="search">搜索</el-button>
            </div>
          </div>
          <div class="addbtn">
           
          </div>
        </div>
    </div>
      <div class="shopitembox">
      
        <div class="item-box" >
          <div class="shopitem" v-for="(item,index) in audioList" v-if="audioList.length>0">
            <img src="/image/loginbg.png" alt="" />
            <div class="itemright">
              <div class="itemtitle">姑娘别哭泣</div>
              <div class="itemID">野哥 4:00</div>
              <div class="itemdel" @click="delitem(item)">
                <i class="el-icon-delete"></i>删除
              </div>
            </div>
          </div>
          
        </div>
        
      </div>
      <el-empty v-if="audioList.length<=0" :image="`${$IMG_URL}/imgs/empty/empty.png`" description="暂无数据" :image-size="imageSize"></el-empty>
      <div class="block">
          <el-pagination
            background
            :page-size="formData.limit"
            :current-page="formData.page"
            layout="total, prev, pager, next, jumper"
            :total="tableData.total"
            @size-change="handleSizeChange"
            @current-change="pageChange"
          />
    </div>
    </div>
  </div>
</template>
 <script>
export default {
  data() {
    return {
      radio1: "个性化灵感",
      input:'',
      imageSize:500,
      options: [],
      formData:{
        page:1,
        keyword:'',
        group_id:'',
        limit:6
      },
      groupList:[],
      tabList:[
        {label:'视频',value:'video'},
        {label:'图文',value:'imags'}
      ],
      tableData:[],
      audioList:[]
    };
  },
  mounted() {},
  methods: {},
};
</script>
 
 <style scoped lang="scss">
.BOX {
  background-color: #e1eaf2;
  width: 100%;
  height: calc(100vh - 100px);
  overflow-x: hidden;
  border-radius: 20px;
  padding-top: 20px;
}


.BOX-card{
  border-radius: 10px;;
}
/* 修改垂直滚动条 */
::-webkit-scrollbar {
    width: 10px !important; /* 修改宽度 */
    border-radius: 18px !important;
  }
   
  /* 修改滚动条轨道背景色 */
  ::-webkit-scrollbar-track {
    background-color: #f1f1f1 !important;
    border-radius: 18px !important;
  }
   
  /* 修改滚动条滑块颜色 */
  ::-webkit-scrollbar-thumb {
    background-color: #ccc !important;
    border-radius: 18px !important;
  }
   
  /* 修改滚动条滑块悬停时的颜色 */
  ::-webkit-scrollbar-thumb:hover {
    background-color: #ccc !important;
  }
  .headbox {
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
  }

.header-box{
  display: flex;
  align-items: center;
}
.select-box{
  margin-left: 40px;;
  display: flex;
  align-items: center;
}
:deep(.searchkey .el-input__inner){
  background: #ECF2F9;
  border:none;
  border-radius: 50px;
  width: 150px;
  color:#696969;
  font-size: 186px;
}

.searchkey{
  width: 300px;
  height: 38px;
  line-height: 38px;
  background: #ECF2F9;
  border:none;
  border-radius: 50px;
  margin-left: 18px;
  color:#696969;
  :deep(.el-input__inner){
    width: 300px;
  }
}
 
input:focus{
  width: 300px;
  height: 38px;
  line-height: 38px;
  background: #ECF2F9;
  border:none;
  border-radius: 50px;
  margin-left: 18px;
  color:#696969;
}



:deep(.select-box .el-input__inner){
  background: #ECF2F9;
  border:none;
  border-radius: 50px;
  width: 150px;
  color:#696969;
  font-size: 16px;
}

.searchBtn{
  background-color: #2758FF;
  color:#fff;
  border-radius: 50px;
  margin-left: 18px;
}

.tzggicon {
  width: 6px;
  height: 22px;
  margin-right: 4px;
}
.shopitembox {
  flex: 1;
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  padding-left: 20px;
  
  .tzggtitle {
    font-size: 24px;
    color: #000;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  .item-box{
    display: flex; flex-wrap: wrap;padding-left:40px ;
   
  }
  .shopitem {
    height: 124px;
    background: #ffffff;
    border-radius: 4px;
    display: flex;
    align-items: center;
    padding: 12px;
    box-sizing: border-box;
    margin-right: 40px;
    margin-bottom: 20px;
    position: relative;
    width: calc(25% - 40px);
    height: fit-content;

    img {
      width: 100px;
      height: 100px;
      border-radius: 50%;
    }
    .itemright {
      flex: 1;
      height: 90%;
      padding: 0 10px;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .itemtitle {
        font-weight: 400;
        font-size: 20px;
        color: #000000;
      }
      .itemID {
        font-weight: 400;
        font-size: 18px;
        color: #666666;
      }
      .itemdel {
        font-weight: 400;
        font-size: 16px;
        color: #2758ff;
        text-align: right;
      }
      .itemdel:hover {
        cursor: pointer;
      }
    }
  }
}

.ccc {
  display: flex;
  .aaaaa,
  .bbbbb {
    background: #ecf2f9;
    padding: 6px;
    border-radius: 40px;

    font-weight: 400;
    color: #696969;
    margin-right: 20px;
  }
  .bbbbb {
    padding: 6px 10px;
  }
}
</style>
 