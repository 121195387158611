
<template>
  <div class="BOX BOX-card">
    <div class="tzggbox">
      <div class="tzggleft">
        <div class="tzggtitle">
          <img class="tzggicon" src="@/assets/hld.png" alt="" />
          通知公告
        </div>
        <div class="tzggitem" v-for="(item,index) in ggList" :key="index">
          <span>{{item.title}}</span>
          <span>{{item.day}}</span>
        </div>
       
      </div>
      <div class="tzggright" >
        <el-carousel height="200px" >
          <el-carousel-item v-for="(item,index) in bannerList" :key="index">
            <img :src="item.url" width="100%" height="100%">
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
    
    <el-row>
      <div class="actionSteps">
        <div  class="actionSteps-item">
          <p  class="actionSteps-item-index">1</p>
          <img  src="@/assets/step3.png" class="actionSteps-item-icon">
          <p > 账号绑定</p>
          <p  class="actionSteps-item-label">多平台、多账号授权</p>
        </div>
        <div  class="actionSteps-item">
          <p  class="actionSteps-item-index">2</p>
          <img  src="@/assets/step4.png" class="actionSteps-item-icon">
          <p > 添加商品</p>
          <p  class="actionSteps-item-label">多平台商品推广</p>
        </div>
        <div  class="actionSteps-item">
          <p  class="actionSteps-item-index">3</p>
          <img  src="@/assets/step3.png" class="actionSteps-item-icon">
          <p > 创建任务</p>
          <p  class="actionSteps-item-label">智能自动化任务体系</p>
        </div>
        <div  class="actionSteps-item">
          <p  class="actionSteps-item-index">4</p>
          <img  src="@/assets/step1.png" class="actionSteps-item-icon">
          <p > 视频剪辑</p>
          <p  class="actionSteps-item-label">满足您的多样化编辑需求</p>
        </div>
        <div  class="actionSteps-item">
          <p  class="actionSteps-item-index">5</p>
          <img  src="@/assets/step5.png" class="actionSteps-item-icon">
          <p > 视频发布</p>
          <p  class="actionSteps-item-label">多平台多方式、超灵活发布任务</p>
        </div>
        <div  class="actionSteps-item">
          <p  class="actionSteps-item-index">6</p>
          <img  src="@/assets/step2.png" class="actionSteps-item-icon">
          <p > 自定义创作</p>
          <p  class="actionSteps-item-label">多素材、多模板个性化创作</p>
        </div>
        
      </div>
     
    </el-row>
    <div class="tzggtitle">
      <img class="tzggicon" src="@/assets/hld.png" alt="" />
      我的数据
    </div>
    <el-row :gutter="20" style="    display: flex;justify-content: center;">
      <el-col :span="6" v-for="(v, i) in jjsplist" :key="i" class="jjspitem" :style="{
            background: `url(${v.img}) no-repeat`,
            backgroundSize: '100% 100%',
          }">
        <div class="jjsptitle">
          <img :src="v.icon" alt="" />
          <div>{{ v.title }}</div>
        </div>
        <div class="jjspbtm">
          <div>
            {{ v.number }}
            <span>已用</span>
          </div>
          <div>
            {{ v.remain }}
            <span>剩余</span>
          </div>
        </div>
      </el-col>
    </el-row>
    <div class="tzggtitle">
      <img class="tzggicon" src="/image/hld.png" alt="" />
      推荐视频
    </div>
    <div class="tjitembox">
      <div class="tjitem" v-for="(item,index) in videoList" :key="index" v-if="item.url">
        <video v-if="item.poster" preload="none" :poster="item.poster" controls :src="item.url" style="width: 100%;"></video>
        <video v-else  :poster="item.poster" controls :src="item.url" style="width: 100%;"></video>
        <div class="tjitem-box">
          <div class="tjitem-box-s">
            <span>点赞量:</span>
            <span class="txt">{{ item.zan }}</span>
          </div>
          <div class="tjitem-box-s">
            <span>播放量:</span>
            <span  class="txt">{{ item.look }}</span>
          </div>
          <div class="tjitem-box-s">
            <span>评论:</span>
            <span  class="txt">{{ item.review }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import {videoListApi,delVideoApi} from '@/api/api.js';
import {ggListApi} from '@/api/api.js';
export default {
  data() {
    return {
      ggList:[],//公告
      jjsplist: [
        {
          title: "剪辑任务",
          icon: require("@/assets/jjsp.png"),
          number: 1,
          remain: "不限",
          img: require("@/assets/glzhs.png"),
        },
        {
          title: "授权账号",
          icon: require("@/assets/qyhsq.png"),
          number: 1,
          remain: "不限",
          img: require("@/assets//jrxh.png"),
        },
        {
          title: "授权商品",
          icon: require("@/assets/ptdysq.png"),
          number: 1,
          remain: "不限",
          img: require("@/assets/lszxh.png"),
        },
        {
          title: "视频数据",
          icon: require("@/assets/spsj.png"),
          number: 1,
          remain: "不限",
          img: require("@/assets/lszcz.png"),
        },
      ],
      bannerList:[
        {id:1,url:'https://xiaobaiya01.oss-cn-beijing.aliyuncs.com/uploads/images/20240709/6cc5a90d58ab87bf2e0113eb66905321.png'}
      ],
      videoList:[]
    };
  },

  mounted() {
   
    this.getGGList();
    this.getVideoList();
  },
  methods: {
    getUserInfo(){
      this.$store.dispatch('user/getInfo');
    },
    getGGList(){
      let that=this;
      ggListApi().then(res=>{
        that.ggList=res.data;
      });
    },
    getVideoList(){
      let that=this;
      that.showTaskModal=false;
      videoListApi({order_type:'fans'}).then(res=>{
        that.videoList=res.data.data;
        
      })
    }
  },
};
</script>
  
  <style scoped  lang="scss">
  .actionSteps{
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 20px 15px;
    // width: 1000px;
    .actionSteps-item {
        background-color: #f5f5f5;
        width: 15.5%;
        padding: 3vh 0;
        border-radius: 10px;
        text-align: center;
        cursor: pointer;
        -webkit-transition: all .5s;
        transition: all .5s;
        position: relative;
        font-size: 18px;
        font-weight: 600;
        .actionSteps-item-index{
            font-size: 24px;
            font-weight: 700;
            color: hsla(0,0%,100%,.8);
            position: absolute;
            top: -5px;
            left: 10px;
            z-index: 10;
            //background-color: #fb0d8c;
            // background-color: #4FBBFE;
            background-color: #B4C5FF;
            border-radius: 5px;
            width: 50px;
            height: 60px;
            line-height: 50px;
            clip-path: polygon(75% 0,100% 50%,75% 100%,0 100%,25% 50%,0 0);
        }
        .actionSteps-item-icon{
            width: 55%;
            margin-bottom: 40px;
            position: relative;
            z-index: 100;
        }
        img {
            vertical-align: middle;
            border-style: none;
        }
        .actionSteps-item-label {
            font-size: 14px;
            margin-top: 12px;
            letter-spacing: 1px;
            font-weight: 400;
        }
    }
    .actionSteps-item:hover {
        -webkit-transform: scale(1.07);
        transform: scale(1.07);
    }
  }
.BOX {
  width: 100%;
  height: calc(100vh - 100px);
  background: #E1EAF2;
  padding: 0 20px;
  overflow-x: hidden;
  .tzggbox {
    width: 100%;
    height: 220px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .tzggright {
      width: 900px;
      height: 200px;
    
      margin-top: 20px;
      margin-left: 20px;
    }

    .tzggleft {
      flex: 1;
      height: 200px;
      margin-top: 20px;
      padding: 0 20px;
      box-sizing: border-box;
      background: #fff;
      display: flex;
      flex-direction: column;
      border-radius: 20px;

      .tzggitem {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        font-weight: 400;
        font-size: 16px;
        color: #666666;
        margin: 2px 0;
      }
    }
  }
}
.tzggtitle {
  font-size: 20px;
  color: #000;
  display: flex;
  align-items: center;
  margin: 10px 0;
}
.tzggicon {
  width: 6px;
  height: 18px;
  margin-right: 4px;
}


.el-col-lg-4-8 {
  width: 20%;
}
.jjspitem:hover{
  transform: scale(1.07);
}
.jjspitem {
  width: 24%;
  height: 190px;
  margin: 20px;
  border-radius: 6px;
  padding: 18px;
  background: #fff;
  cursor: pointer;
  -webkit-transition: all .5s;
  transition: all .5s;
  position: relative;
  font-size: 18px;
  font-weight: 600;
  .jjsptitle {
    width: 100%;
    display: flex;
    align-items: center;

    font-weight: 400;
    font-size: 20px;
    color: #000000;
    img {
      width: 36px;
      height: 36px;
    }
  }

  .jjspbtm {
    width: 100%;
    height: 120px;
    display: flex;
    align-items: center;
    justify-content: space-around;

    span {
      font-weight: 400;
      font-size: 18px;
      color: #000000;
    }
    div {
      display: flex;
      flex-direction: column;
      font-weight: 500;
      font-size: 20px;
      color: #2758ff;
    }
  }
}

.tjitembox {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  .tjitem {
    // margin-right: 20px;
    // margin-bottom: 20px;
    // width: 360px;
    // height: 460px;
    // background: #aaa;
    // border-radius: 10px;
    position: relative;
    width: calc(20% - 20px);
    height: fit-content;
    line-height: 1;
    margin-left: 20px;
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-radius: 14px;
    overflow: hidden;
    background: #F5F5F5;
    cursor: pointer;
    transform-origin: 50% 0;
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
    height: 460px;
    .tjitem-box{
      position:absolute;
      top: 0;
      right: 0;
      padding:12px;
      .tjitem-box-s{
        background-color: rgba(0,0,0,.4);
        padding:6px 16px;
        border-radius: 25px;
        margin-bottom: 10px;
        color:#fff;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        span{
          font-size: 14px;
          color:#fff;
        }
        .txt{
          margin-left: 8px;
        }
      }
    }
  }
}
</style>
  