import request from './request'
/**
  * @description 验证码
  */
export function captchaApi() {
  return request.get(`captcha`)
}
/**
  * @description 登录
  */
export function login(data) {
  return request.post(`user/accountLogin`, data)
}

/**
 * @returns 用户信息
 */
export function getInfo(){
    return request.get(`user/index`)
}

/**
  * @description 用户信息
  */
export function profileApi(data){
  return request.post(`user/profile`, data)
}

/**
 * 退出登录
 * @returns 
 */
export function logout() {
    return request.post(`user/logout`)
  }

export function editFormApi(data){

}

/**
 * 发送验证码
 * @param {*} data 
 * @returns 
 */
export function sendCodeApi(data){
    return request.post(`sms/send`, data)
}

export function mobileLoginApi(data){
  return request.post(`user/smsLogin`, data)
}

/**
 * 注册
 * @param {*} data 
 * @returns 
 */
export function registerApi(data){
  return request.post(`user/register`, data)
}



