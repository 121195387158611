
import Element from 'element-ui'
import router from './router'
import store from './store/index'

import { getToken } from '@/utils/auth' // get token from cookie

import { MessageBox, Message } from 'element-ui'
const roterPre='';
const whiteList = [`${roterPre}/login`, '/auth-redirect'] // no redirect whitelist
router.beforeEach(async(to, from, next) => {
 
        // set page title
        // document.title = getPageTitle(to.meta.title)

        // determine whether the user has logged in
        const hasToken = getToken();
      
        if (hasToken) {
            if (to.path === `${roterPre}/login`) {
                // if is logged in, redirect to the home page
                next({ path: '/home' })
               
            } else {
                if(from.fullPath === '/' || from.path !==`${roterPre}/login`){
                    next()
                }else{
                    next();
                }
            }
        } else {
        /* has no token*/
            if (whiteList.indexOf(to.path) !== -1) {
                // in the free login whitelist, go directly
                next()
            } else {
                console.log(from.fullPath === '/' && from.path !==`${roterPre}/login`);
                // other pages that do not have permission to access are redirected to the login page.
            
                //await store.dispatch('user/resetToken')
                next(`${roterPre}/login`)
                if(from.fullPath === '/' && from.path !==`${roterPre}/login`){
                    next()
                }else{
                   
                }
            }
        }

        await store.dispatch('config/initConfig');

  
})
router.afterEach(() => {
  
})
