
<template>
  <div class="BOX BOX-card">
    <div class="headbox">
      <div class="shopitembox">
        <div class="header-box">
          <div class="tzggtitle">
            <img class="tzggicon" src="@/assets/hld.png" alt="" />
          视频库
        </div>
          <div class="select-box">
            <el-select v-model="formData.group_id" clearable placeholder="选择类型" @change="typeChange">
              <el-option
                v-for="item in typeList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
                clearable="true"
                :disabled="item.disabled">
              </el-option>
            </el-select>
            <div style="width: 20px;"></div>
            <el-select v-model="formData.group_id" clearable placeholder="选择分组" @change="formChange">
              <el-option
                v-for="item in groupList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
                clearable="true"
                :disabled="item.disabled">
              </el-option>
            </el-select>
            <div style="width: 20px;"></div>
            <el-select v-model="formData.status" clearable placeholder="选择状态" @change="formStatusChange">
              <el-option
                v-for="item in statusList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
                clearable="true"
                :disabled="item.disabled">
              </el-option>
            </el-select>

            <el-input class="searchkey" v-model="formData.keyword" placeholder="请输入关键词" :clearable="true"></el-input>
            <el-button class="searchBtn" @click="getList">搜索</el-button>
          </div>
        </div>
      </div>

      <div class="addbtn">
        <el-button type="primary" icon="el-icon-plus" @click="addVideo">添加视频</el-button>
      </div>
    </div>
    <div class="tjitembox">
      <div  class="tjitem" v-for="(item,index) in videoList" :key="index">
        <!-- <div  class="item-check"></div> -->
        <!---->
        <video v-if="item.poster" :poster="item.poster" 
        :src="item.url" preload="none" controls="controls"></video>
        <video v-else 
        :src="item.url"  controls="controls"></video>
        <!---->
        <h2 class="title">{{item.task_sn}}</h2>
        <div data-v-52396443="" class="item-bottom">
          <span data-v-52396443="">{{item.createtime}}</span>
          <div  class="item-bottom__right">
            <i class="el-tooltip el-icon-download" aria-describedby="el-tooltip-4245" tabindex="0" @click="downloadFile(item)"></i>
            <!-- <i  class="el-tooltip el-icon-edit-outline" aria-describedby="el-tooltip-9973" tabindex="0"></i> -->
            <i  class="el-tooltip el-icon-delete" aria-describedby="el-tooltip-301" tabindex="0" @click="del(item)"></i>
          </div>
        </div>
        <div class="item-label" v-if="item.public_status==0">未发布</div>
        <div class="item-label1" v-if="item.public_status==1">已发布</div>
        <div class="item-label1" v-if="item.public_status==2">全平台发布</div>
        <div style="margin-top: 20px;margin-left: 14px;display: flex;align-items: center;height: 40px;" >
          <span>平台</span>
          <el-tooltip class="item" effect="dark" content="视频已经发布过的平台" placement="top-start">
            <i class="el-icon-question"></i>
          </el-tooltip>
          <div style="margin-left: 20px;" v-if="item.platformList.length>0">
            <img v-for="(items,indexs) in item.platformList" :key="indexs" :src="items.url" style="width: 30px;height: 30px;border-radius: 10px;margin-right: 5px;" v-if="items">
          </div>
          <div  style="margin-left: 20px;" v-if="item.platformList.length<=0">暂未发布</div>
        </div>
          

      </div>
    </div>
  
   

    <div class="block">
          <el-pagination
            background
            :page-size="formData.limit"
            :current-page="formData.page"
            layout="total, prev, pager, next, jumper"
            :total="tableData.total"
            @size-change="handleSizeChange"
            @current-change="pageChange"
          />
    </div>
  </div>
</template>
  <script>
  import {videoListApi,delVideoApi} from '@/api/api.js';
export default {
  data() {
    return {
      radio1: "个性化灵感",
      input:'',
      imageSize:500,
      options: [],
      formData:{
        page:1,
        keyword:'',
        group_id:'',
        limit:10,
        status:''
      },
      groupList:[],
      typeList:[
        {label:'视频',value:'1'},
        {label:'图文',value:'0'}
      ],
      statusList:[
      {label:'未发布',value:'0'},
      {label:'可发布',value:'1'},
      {label:'已完成',value:'2'}
      ],
      tableData:[],
      videoList:[]
    };
  },

  mounted() {
    this.getList();
  },
  methods: {
    addVideo(){
      this.$message('暂未开放');
    },
    getList(){
      let that=this;
      that.showTaskModal=false;
      videoListApi(that.formData).then(res=>{
        console.log(res);
        that.tableData=res.data;
        that.videoList=res.data.data;
        
      })
    },
    formChange(e){
      this.formData.group_id=e;
      this.formData.page=1;
      this.videoList=[];
      this.getList();
    },
    formStatusChange(e){
      this.formData.status=e;
      this.formData.page=1;
      this.videoList=[];
      this.getList();
    },
    typeChange(e){
      this.formData.type=e;
      this.formData.page=1;
      this.videoList=[];
      this.getList();
    },
    handleSizeChange(e){
      this.formData.page=e;
      this.getList();
    },
    pageChange(e){
      this.formData.page=e;
      this.getList();
    },
    del(item){
      let that=this;
      that.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          delVideoApi({id:item.id}).then(res=>{
            that.$message.success(res.msg);
            that.getList();
          });
        }).catch(() => {
                   
        });
    },
    downloadFile(item) {
      // URL of the file to be downloaded
      const fileUrl = item.url;
      // Name of the file to be downloaded
      const fileName = item.task_sn+'.mp4';
      
      const link = document.createElement('a');
      link.href = fileUrl;
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  },
};
</script>
  
  <style scoped lang="scss">
.BOX {
  width: 100%;
  height: calc(100vh - 100px);
  padding: 10px 20px;
  background: #e1eaf2;
}
.item-label{
    position: absolute;
    top:0;
    left: 0;
    background-color: #ccc;
    padding:5px 20px;
    color:#fff;
    border-bottom-right-radius: 40px;
}
.item-label1{
    position: absolute;
    top:0;
    left: 0;
    background-color: #B4C5FF;
    padding:5px 20px;
    color:#fff;
    border-bottom-right-radius: 50px;
}
.BOX-card{
  border-radius: 10px;;
}
/* 修改垂直滚动条 */
::-webkit-scrollbar {
    width: 10px !important; /* 修改宽度 */
    border-radius: 18px !important;
  }
   
  /* 修改滚动条轨道背景色 */
  ::-webkit-scrollbar-track {
    background-color: #f1f1f1 !important;
    border-radius: 18px !important;
  }
   
  /* 修改滚动条滑块颜色 */
  ::-webkit-scrollbar-thumb {
    background-color: #ccc !important;
    border-radius: 18px !important;
  }
   
  /* 修改滚动条滑块悬停时的颜色 */
  ::-webkit-scrollbar-thumb:hover {
    background-color: #ccc !important;
  }

.header-box{
  display: flex;
  align-items: center;
}
.select-box{
  margin-left: 40px;;
  display: flex;
  align-items: center;
}
:deep(.searchkey .el-input__inner){
  background: #ECF2F9;
  border:none;
  border-radius: 50px;
  width: 150px;
  color:#696969;
  font-size: 186px;
}

.searchkey{
  width: 300px;
  height: 38px;
  line-height: 38px;
  background: #ECF2F9;
  border:none;
  border-radius: 50px;
  margin-left: 18px;
  color:#696969;
  :deep(.el-input__inner){
    width: 300px;
  }
}
 
input:focus{
  width: 300px;
  height: 38px;
  line-height: 38px;
  background: #ECF2F9;
  border:none;
  border-radius: 50px;
  margin-left: 18px;
  color:#696969;
}



:deep(.select-box .el-input__inner){
  background: #ECF2F9;
  border:none;
  border-radius: 50px;
  width: 150px;
  color:#696969;
  font-size: 16px;
}

.searchBtn{
  background-color: #2758FF;
  color:#fff;
  border-radius: 50px;
  margin-left: 18px;
}


.headbox {
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}
.tzggicon {
  width: 6px;
  height: 22px;
  margin-right: 4px;
}
.shopitembox {
  flex: 1;
  padding: 20px;
  box-sizing: border-box;
  .tzggtitle {
    font-size: 24px;
    color: #000;
    display: flex;
    align-items: center;
  }
  .shopitem {
    width: 370px;
    height: 124px;
    background: #ffffff;
    border-radius: 4px;
    display: flex;
    align-items: center;
    padding: 12px;
    box-sizing: border-box;
    margin-right: 20px;
    margin-bottom: 20px;

    img {
      width: 100px;
      height: 100px;
      border-radius: 50%;
    }
    .itemright {
      flex: 1;
      height: 90%;
      padding: 0 10px;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .itemtitle {
        font-weight: 400;
        font-size: 20px;
        color: #000000;
      }
      .itemID {
        font-weight: 400;
        font-size: 18px;
        color: #666666;
      }
      .itemdel {
        font-weight: 400;
        font-size: 16px;
        color: #2758ff;
        text-align: right;
      }
      .itemdel:hover {
        cursor: pointer;
      }
    }
  }
}


.tjitembox {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  overflow-x: hidden;
  height: calc(100vh - 250px);
  padding-left: 20px;
  .tjitem:hover{
    -webkit-box-shadow: 0 2px 4px 0 rgba(0,0,0,.1);
    box-shadow: 0 2px 4px 0 rgba(0,0,0,.1);
  }
  .tjitem {
    position: relative;
    width: calc(20% - 40px);
    height: fit-content;
    line-height: 1;
    margin-right: 40px;
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-radius: 14px;
    overflow: hidden;
    background: #F5F5F5;
    cursor: pointer;
    transform-origin: 50% 0;
    -webkit-transition: all .3s ease;
    transition: all .3s ease;

    .item-check{
      position: absolute;
      top: 14px;
      left: 14px;
      z-index: 11;
      border: 1px solid #dcdfe6;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      background-color: #fff;
      -webkit-transition: border-color .25s cubic-bezier(.71,-.46,.29,1.46),background-color .25s cubic-bezier(.71,-.46,.29,1.46);
      transition: border-color .25s cubic-bezier(.71,-.46,.29,1.46),background-color .25s cubic-bezier(.71,-.46,.29,1.46);
      }
      video{
        background: #000;
        width: 100%;
        height: 260px;
      }
    .title{
      margin: 10px 14px 6px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      color: #000;
      font-size: 16px;
      font-weight: 500;
      }
      .item-bottom{
        margin-top: 20px;
        padding:0 14px;
      }
    .item-bottom__right,.item-bottom{
      display: flex;
      justify-content: space-between;
      align-items: center;
      span{
        font-size: 14px;
        color: #999;
      }
    }
    .item-bottom__right i {
      margin-right: 6px;
      font-size: 16px;
    }
    .flex {
        display: flex;
        -ms-flex-align: center;
        align-items: center;
    }

  }
  .s{
    margin-right: 20px;
    margin-bottom: 20px;
    width: 360px;
    height: 600px;
    background: #aaa;
    border-radius: 10px;
  }
}
</style>
  