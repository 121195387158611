
<template>
  <div>
    <div class="BOX BOX-card">
      <div class="shopitembox">
        <div class="header-box">
          <div class="tzggtitle">
            <img class="tzggicon" src="@/assets/hld.png" alt="" />
          商品库
        </div>
          <div class="select-box">
            <el-select v-model="formData.platform" clearable placeholder="选择平台" @change="formChangeP">
              <el-option
                v-for="item in platformList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
                clearable="true"
                :disabled="item.disabled">
              </el-option>
            </el-select>
            <el-select style="margin-left: 10px;" v-model="formData.group_id" clearable placeholder="选择分组" @change="formChange">
              <el-option
                v-for="item in groupList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
                clearable="true"
                :disabled="item.disabled">
              </el-option>
            </el-select>

            <el-input class="searchkey" v-model="formData.keyword" placeholder="请输入关键词" :clearable="true"></el-input>
            <el-button class="searchBtn" @click="search">搜索</el-button>
            <el-button class="addBtn" @click="addGoods">添加商品</el-button>
          </div>
        </div>
        

        <div class="item-box">

          <div class="shopitem" v-for="(item,index) in goodsList" v-if="goodsList.length>0">
            <img :src="item.thumb_image" alt="" style="border-radius: 5px;"/>
            <div class="itemright">
              <div class="itemtitle">{{item.title}}</div>
              <div class="itemID">ID：{{ item.unique_id }}</div>
              <div style="display: flex;justify-content: space-between;">
                <div class="itemdel" v-if="item.is_kt==0" @click="changeKt(item.id)"> 启用抠图 </div>
                <div class="itemdel" v-if="item.is_kt==1" @click="changeKt(item.id)" style="color:greenyellow"> 启用原图 </div>
                <!-- <div class="itemdel" @click="delitem(item)">
                  <i class="el-icon-tickets"></i>查看详情
                </div> -->
              </div>
            </div>
          </div>

          <el-empty v-if="goodsList.length<=0" :image="`${$IMG_URL}/imgs/empty/empty.png`" description="暂无数据" :image-size="imageSize"></el-empty>
        </div>
      </div>
      <!-- 分页-->
      
      <div class="block">
          <el-pagination
            background
            :page-size="formData.limit"
            :current-page="formData.page"
            layout="total, prev, pager, next, jumper"
            :total="tableData.total"
            @size-change="handleSizeChange"
            @current-change="pageChange"
          />
      </div>

      <!-- 添加弹窗-->
      <el-dialog :visible.sync="goodsModal" width="60%" custom-class="dialog-box-cl" top="10vh" >
        <div class="dialog-content-box">
          <h1 style="margin: 20px 0 0 20px;font-size: 24px;border-bottom: 1px solid #ccc;padding-bottom: 10px;">自定义商品</h1>
          <div class="form-box">
              <div class="form-box-item">
                <div class="item-t">商品ID
                  <el-tooltip
                    class="item"
                    effect="dark"
                    content="留空则自动生成"
                    placement="top-start"
                  >
                    <i class="el-icon-question"></i>
                  </el-tooltip>
                </div>
                <div class="input-box">
                  <input class="input-box-in" type="text" v-model="form.unique_id"  placeholder="请输入商品ID"/>
                </div>
              </div>
              <div class="form-box-item">
                <div class="item-t">商品名称</div>
                <div class="input-box">
                  <input class="input-box-in" type="text" v-model="form.title"  placeholder="请输入商品名称"/>
                </div>
              </div>
              <div class="form-box-item">
                <div class="item-t">商品买点</div>
                <div class="input-box">
                  <input class="input-box-in" type="text" v-model="form.buy_point"  placeholder="请输入商品卖点(纤维涤纶,针织镂空)"/>
                </div>
              </div>
              <div class="form-box-item">
                <div class="item-t">选择平台</div>
                <div class="input-box" style="background: none;">
                  <el-select v-model="form.platform" clearable placeholder="请选择所属平台">
                    <el-option
                      v-for="item in platformList"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                    </el-option>
                  </el-select>
                </div>
              </div>

              <div class="form-box-item">
                <div class="item-t">选择分组</div>
                <div class="input-box" style="background: none;">
                  <el-select  v-model="form.group_id" clearable placeholder="选择所属分组" @change="formChange">
                    <el-option
                      v-for="item in groupList"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                      clearable="true"
                      :disabled="item.disabled">
                    </el-option>
                  </el-select>
                </div>
              </div>
              <div class="form-box-item">
                <div class="item-t">上传图片
                  <el-tooltip
                    class="item"
                    effect="dark"
                    content="建议尺寸：750*750,图片最多上传5张"
                    placement="top-start"
                  >
                    <i class="el-icon-question"></i>
                  </el-tooltip>
                </div>
                
                <el-upload
                style="margin-left: 20px;"
                  action="https://kt.breekj.top/addons/alioss/index/testInit"
                  list-type="picture-card"
                  :on-remove="handleRemove"
                  :on-success="handleSuccess"
                  :file-list="fileList"
                  :multiple="true"
                   :limit="5"
                  :before-upload="beforeUpload"
                >
                  <i class="el-icon-plus" style="width: 80px;"></i>
                </el-upload>

              </div>
          </div>
        </div>
        <div slot="footer" class="dialog-footer">
          <el-button class="save-btn" type="primary" @click="saveGoods" style="width: 300px;">保 存</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>
 <script>
 import {goodsListApi,goodsGroupApi,checkKtApi,addGoodsApi} from '@/api/api.js';
export default {
  data() {
    return {
      goodsModal:false,
      imageSize:500,
      options: [],
      formData:{
        page:1,
        keyword:'',
        group_id:'',
        limit:9,
        platform:''
      },
      form:{
        unique_id:'',
        platform:'',
        group_id:'',
        buy_point:'',
        title:'',
        images:''
      },
      groupList:[],
      tableData:[],
      goodsList:[],
      platformList:[
        {label:'抖音',value:'douyin'},
        {label:'快手',value:'fasthand'},
        {label:'小红书',value:'redbook'},
      ],
      fileList: [],
    };
  },
  mounted(){
    this.getGroupList();
    this.getList();
  },
  methods: {
    beforeUpload(file) {
      const isLt5M = file.size / 1024 / 1024 < 5;
      if(this.fileList.length>=5){
       this.$message.error('商品图最多上传5张图片');
        return false;
      }
      if (!isLt5M) {
        this.$message.error('上传图片大小不能超过 5MB!');
      }
      return isLt5M;
    },
    handleRemove(file, fileList) {
      this.fileList = fileList;
    },
    handleSuccess(response, file, fileList) {
     
      this.fileList=fileList;
      
    },
    addGoods(){
      this.goodsModal=true;
    },
    saveGoods(){
      let that=this;
      that.fileList.map(item=>{
        that.form.images+=item.response.data.url+',';
      })
     
    
      addGoodsApi(that.form).then(res=>{
        that.$message.success(res.msg);
        that.goodsModal=false;
        that.form={
          unique_id:'',
          platform:'',
          group_id:'',
          buy_point:'',
          title:'',
          images:''
        }
        that.fileList=[];
        that.getList();
      });
    },
    search(){
      this.formData.page=1;
      this.tableData=[];
      this.getList();
    },
    getList(){
      let that=this;
      goodsListApi(that.formData).then(res=>{
        console.log(res);
        that.tableData=res.data;
        that.goodsList=res.data.data;
        
      })
    },
    getGroupList(){
      let that=this;
      goodsGroupApi().then(res=>{
        that.groupList=res.data.labelList;
      });
    },
    changeKt(id){
      let that=this;
      checkKtApi({id:id}).then(res=>{
        that.$message.success(res.msg);
        that.getList();
      });
    },
    handleSizeChange(e){
      console.log(e);
      this.formData.group_id=e;
      this.formData.page=1;
      this.goodsList=[];
      this.getList();
    },
    pageChange(e){
      this.formData.page=e;
      this.getList();
    },
    formChange(e){
      this.formData.group_id=e;
      this.formData.page=1;
      this.goodsList=[];
      this.getList();
    },
    formChangeP(e){
      this.formData.platform=e;
      this.formData.page=1;
      this.goodsList=[];
      this.getList();
    }
  },
};
</script>
 
 <style scoped lang="scss">
 input:focus {
    outline: none;
    margin: 0 !important;
}
 /* 修改垂直滚动条 */
::-webkit-scrollbar {
    width: 10px !important; /* 修改宽度 */
    border-radius: 18px !important;
  }
   
  /* 修改滚动条轨道背景色 */
  ::-webkit-scrollbar-track {
    background-color: #f1f1f1 !important;
    border-radius: 18px !important;
  }
   
  /* 修改滚动条滑块颜色 */
  ::-webkit-scrollbar-thumb {
    background-color: #ccc !important;
    border-radius: 18px !important;
  }
   
  /* 修改滚动条滑块悬停时的颜色 */
  ::-webkit-scrollbar-thumb:hover {
    background-color: #ccc !important;
  }
  .form-box{
      display: flex;
      flex-wrap: wrap;
      margin: 28px 48px 0 48px;
      flex-direction: column;
      .form-box-item{
        display: flex;
        align-items: center;
        margin: 20px;
        .item-t{
          font-size: 16px;
          font-weight: 600;
          color: #000000;
          width: 80px;
          text-align: center;
          display: flex;
          align-items: center;
        }
        .input-box{
          margin-top: 10px;
          background-color: #ECF2F9;
          padding:0 8px;
          border-radius: 4px;
          margin-left: 20px;
          .input-box-in{
            height: 48px;
            line-height: 48px;
            background-color: #ECF2F9;
            width: 400px;
            border: none;
            border-radius: 4px;
          }
          
        }
      }
    }
.BOX {
  background-color: #e1eaf2;
  width: 100%;
  height: calc(100vh - 100px);
  overflow-x: hidden;
}

.BOX-card{
  border-radius: 10px;;
}

.header-box{
  display: flex;
  align-items: center;
  margin-bottom: 40px;
}
.select-box{
  margin-left: 40px;;
}
:deep(.searchkey .el-input__inner){
  background: #ECF2F9;
  border:none;
  border-radius: 50px;
  width: 150px;
  color:#696969;
  font-size: 186px;
}

.searchkey{
  width: 300px;
  height: 38px;
  line-height: 38px;
  background: #ECF2F9;
  border:none;
  border-radius: 50px;
  margin-left: 18px;
  color:#696969;
  :deep(.el-input__inner){
    width: 300px;
  }
}
 
input:focus{
  width: 300px;
  height: 38px;
  line-height: 38px;
  background: #ECF2F9;
  border:none;
  border-radius: 50px;
  margin-left: 18px;
  color:#696969;
}



:deep(.select-box .el-input__inner){
  background: #ECF2F9;
  border:none;
  border-radius: 50px;
  width: 150px;
  color:#696969;
  font-size: 16px;
}

.searchBtn{
  background-color: #2758FF;
  color:#fff;
  border-radius: 50px;
  margin-left: 18px;
}
.tzggicon {
  width: 6px;
  height: 22px;
  margin-right: 4px;
}
.shopitembox {
  flex: 1;
  padding: 18px;
  box-sizing: border-box;
  .tzggtitle {
    font-size: 24px;
    color: #000;
    display: flex;
    align-items: center;
  }
  .item-box{
    display: flex; flex-wrap: wrap;padding-left: 50px;
  }
  .shopitem {
    width: 28.5%;
    height: 184px;
    background: #ffffff;
    border-radius: 4px;
    display: flex;
    align-items: center;
    padding: 12px;
    box-sizing: border-box;
    margin-right: 50px;
    margin-bottom: 50px;

    

    img {
      width: 160px;
      height: 160px;
    }
    .itemright {
      flex: 1;
      height: 90%;
      padding: 0 10px;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .itemtitle {
        font-weight: 400;
        font-size: 18px;
        color: #000000;
      }
      .itemID {
        font-weight: 400;
        font-size: 18px;
        color: #666666;
      }
      .itemdel {
        font-weight: 400;
        font-size: 14px;
        color: #2758ff;
        text-align: right;
      }
      .itemdel:hover {
        cursor: pointer;
      }
    }
  }
}
</style>
 