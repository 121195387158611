<template>
    <div class="goods-box">
        <div class="goods-box-title" slot="title">商品列表</div>
        <div class="goods-box-content">
            <div class="box-card-header kt-flex">
                <el-select v-model="formData.group_id" :clearable="true" placeholder="选择分组" @change="formChange">
                    <el-option
                        v-for="item in groupList"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                        clearable="true"
                        :disabled="item.disabled">
                    </el-option>
                </el-select>
                <el-input style="width: 250px;margin-right: 10px;margin-left: 10px;" :clearable="true"  v-model="formData.keyword" placeholder="请填写关键词"></el-input>
                <el-button class="addBtn add-btn" @click="search">查询</el-button>
            </div>
            <div class="goods-box-card">
                <div class="card-item" v-for="(item,index) in goodsList" :key="index" @click="chooseGoods(item)">
                    <img class="thumb" :src="item.thumb_image">
                    <div class="item-title">{{ item.title }}</div>
                    <div class="item-label">{{ item.label_name }}</div>
                    <div class="item-check" :class="check_id==item.id ? 'item-active':'' ">
                        <i class="el-icon-check" v-if="check_id==item.id" style="color:#fff;"></i>
                    </div>
                </div>
            </div>
            <div class="goods-box-page">
                <div>
                    <el-pagination
                    background
                    :page-size="formData.limit"
                    :current-page="formData.page"
                    layout="total, prev, pager, next, jumper"
                    :total="tableData.total"
                    @size-change="handleSizeChange"
                    @current-change="pageChange"/>
                </div>
                
            </div>
        </div>
        <div slot="footer" class="goods-box-footer">
            <el-button @click="chooseComplete" class="addBtn add-btn">确定选择</el-button>
        </div>
    </div>
</template>
<script>
import {goodsListApi,goodsGroupApi,checkKtApi} from '@/api/api.js';
export default {
  data() {
    return {
        formData:{
            limit:10,
            page:1,
            keyword:'',
            group_id:''
        },
        tableData:{
            total:100
        },
        groupList:[],
        goodsList:[],
        check_id:'',
        goods:[]
    };
  },
  mounted() {
    this.getGroupList();
    this.getList();
  },
  methods: {
    getGroupList(){
      let that=this;
      goodsGroupApi().then(res=>{
        that.groupList=res.data.labelList;
      });
    },
    search(){
      this.formData.page=1;
      this.tableData=[];
      this.getList();
    },
    getList(){
      let that=this;
      goodsListApi(that.formData).then(res=>{
        console.log(res);
        that.tableData=res.data;
        that.goodsList=res.data.data;
        
      })
    },
    formChange(){
      this.formData.group_id=e;
    },
    handleSizeChange(e){
      console.log(e);
      this.formData.group_id=e;
      this.formData.page=1;
      this.goodsList=[];
      this.getList();
    },
    pageChange(e){
      this.formData.page=e;
      this.getList();
    },
    chooseGoods(item){
        this.check_id=item.id;
        this.goods=item;
    },
    chooseComplete(){
        if(!this.check_id || !this.goods){
            this.$message.error('请选择商品');
        }
        this.$emit('send-data',this.goods);
    }
  },
};
</script>
<style lang="scss" scoped>
    .goods-box{
        width: 100%;
        background: #fff;
        height: 820px;
        border-radius: 20px;
        .goods-box-title{
            font-size: 16px;
            color: #333;
            font-weight: 700;
            padding: 20px 40px;
            border-bottom: 1px solid #f0f0f0;
        }
        .goods-box-content{
            padding:10px ;
            width: 100%;
            .goods-box-card{
                width: 100%;
                display: flex;
                flex-wrap: wrap;
                overflow-x: hidden;
                height: calc(100vh - 430px);
                padding-left: 20px;
                margin-top: 10px;
                .card-item{
                    position: relative;
                    width: calc(20% - 20px);
                    // height: fit-content;
                    line-height: 1;
                    margin-right: 20px;
                    margin-bottom: 20px;
                    padding-bottom: 20px;
                    border-radius: 14px;
                    overflow: hidden;
                    background: #F8F8F8;
                    cursor: pointer;
                    transform-origin: 50% 0;
                    -webkit-transition: all .3s ease;
                    transition: all .3s ease;
                    height: fit-content;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    .thumb{
                        width: 220px;
                        height: 220px;
                        margin: 10px auto;
                        border-radius: 14px;
                    }
                    .item-title{
                        font-size: 16px;
                        color:#000;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: block;
                        width: 220px;
                        font-weight: 600;
                        margin-top: 5px;
                    }
                    .item-label{
                        position: absolute;
                        left: 0;
                        background-color: #F56C6C;
                        padding:5px 20px;
                        color:#fff;
                        border-bottom-right-radius: 50px;
                    }
                    .item-check{
                        position: absolute;
                        top: 14px;
                        right: 4px;
                        z-index: 11;
                        border: 1px solid #dcdfe6;
                        width: 30px;
                        height: 30px;
                        border-radius: 50%;
                        background-color: rgba(0,0,0,.6);
                        -webkit-transition: border-color .25s cubic-bezier(.71,-.46,.29,1.46),background-color .25s cubic-bezier(.71,-.46,.29,1.46);
                        transition: border-color .25s cubic-bezier(.71,-.46,.29,1.46),background-color .25s cubic-bezier(.71,-.46,.29,1.46);
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        font-size: 25px;
                    }
                    .item-active{
                        background: #2758FF;
                    }
                }
                .card-item:hover{
                    -webkit-box-shadow: 0 2px 4px 0 rgba(0,0,0,.1);
                     box-shadow: 2px 2px 4px 2px rgba(0,0,0,.1);
                }
            }
            .goods-box-page{
                position: absolute;
                bottom: 70px;
                right: 0;
                margin-right: 20px;
               
                
            }
        }
        .goods-box-footer{
            position: absolute;
            bottom: 0;
            width: 100%;
            height: 60px;
            background-color: #f3f6f9;
            border-bottom-left-radius: 20px;
            border-bottom-right-radius: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .add-btn{
            height: 42px !important;
            display: flex;
            align-items: center;
            width: 120px;
            justify-content: center;
            font-size: 14px;
        }
    }
</style>