
import Vue from 'vue'
import axios from 'axios'
import store from '@/store'
import SettingMer from '@/utils/setting'
const instance = axios.create({
  baseURL: SettingMer.https,
  timeout: 60000
})
const defaultOpt = { login: true }

function baseRequest(options) {
  const token = store.getters.token
  const headers = options.headers || {}
  if(token) {
    headers['token'] = token
    options.headers = headers
  }
  return new Promise((resolve, reject) => {

    instance(options).then(res => {
      const data = res.data || {}
      if(res.status !==200) {
        const errorMessage = '请求失败，请重试';
        Vue.prototype.$alert(errorMessage, '错误', {
          confirmButtonText: '确定',
          type: 'error'
        });
        return reject(new Error(errorMessage));
      }
       if(data.code === 1) {
        return resolve(data, res)
      } else if(data.code === 0) {
        Vue.prototype.$message.error(data.msg);
      }else {
        
        let errorMessage = '请求失败，请刷新重试';
        Vue.prototype.$alert(errorMessage, '错误', {
          confirmButtonText: '确定',
          type: 'error'
        });
        return;
      }
    }).catch(error=>{
      let errorMessage = '请求失败，请重试';
      if (error.response) {
        // 服务器返回了一个错误响应（不在2xx的范围内）
        errorMessage = `请求失败，状态码：${error.response.status}`;
      } else if (error.request) {
        // 请求已经发出，但是没有收到响应
        errorMessage = '请求失败，服务器无响应';
      } else {
        // 发生了一些意外的错误
        errorMessage = error.message;
      }
      Vue.prototype.$alert(errorMessage, '错误', {
        confirmButtonText: '确定',
        type: 'error'
      });
      // reject(new Error(errorMessage));
      return ;
    })
  })
}

/**
 * http 请求基础类
 * 参考文档 https://www.kancloud.cn/yunye/axios/234845
 *
 */
const request = ['post', 'put', 'patch', 'delete'].reduce((request, method) => {
  /**
   *
   * @param url string 接口地址
   * @param data object get参数
   * @param options object axios 配置项
   * @returns {AxiosPromise}
   */
  request[method] = (url, data = {}, options = {}) => {
    return baseRequest(
      Object.assign({ url, data, method }, defaultOpt, options)
    )
  }
  return request
}, {});

['get', 'head'].forEach(method => {
  /**
   *
   * @param url string 接口地址
   * @param params object get参数
   * @param options object axios 配置项
   * @returns {AxiosPromise}
   */
  request[method] = (url, params = {}, options = {}) => {

    return baseRequest(
      Object.assign({ url, params, method }, defaultOpt, options)
    )
  }
})
export default request
