import Vue from 'vue'
import VueRouter from 'vue-router'
import Homeindex from '@/views/home/index.vue'
import home from '@/views/home/home.vue'
import shop from '@/views/shop/shop.vue'
import create from '@/views/create/create.vue'
import account from '@/views/account/account.vue'
import audio from '@/views/audio/audio.vue'
import video from '@/views/video/video.vue'
import vip from '@/views/vip/vip.vue'
import login from '@/views/login/login.vue'
import source from '@/views/source/source.vue'
import task from '@/views/task/task.vue'
import taskVideo from '@/views/task/taskVideo.vue'
import taskAdd from '@/views/task/taskAdd.vue'
import shoplist from '@/views/shop/shoplist.vue'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'index',
    component: Homeindex,
    meta: { title: 'index' },
    children: [
      {
        path: '/task',
        name: 'task',
        component: task,
        meta: { title: '智能发布' },
      },
      {
        path: '/taskVideo',
        name: 'taskVideo',
        component: taskVideo,
        meta: { title: '视频任务' },
      },
      {
        path: '/taskAdd',
        name: 'taskAdd',
        component: taskAdd,
        meta: { title: '添加发布计划' },
      },
      {
        path: '/home',
        name: 'home',
        component: home,
        meta: { title: '首页' },
      },
      {
        path: '/shop',
        name: 'shop',
        component: shop,
        meta: { title: '商品库' },
      },
      {
        path: '/create',
        name: 'create',
        component: create,
        meta: { title: '视频制作' },
      },
      {
        path: '/account',
        name: 'account',
        component: account,
        meta: { title: '账号绑定' },
      },
      {
        path: '/audio',
        name: 'audio',
        component: audio,
        meta: { title: '音频库' },
      },
      {
        path: '/video',
        name: 'video',
        component: video,
        meta: { title: '视频库' },
      },
      {
        path: '/vip',
        name: 'vip',
        component: vip,
        meta: { title: '会员中心' },
      },
      {
        path: '/source',
        name: 'source',
        component: source,
        meta: { title: '图文素材' },
      },
      {
        path: '/shoplist',
        name: 'source',
        component: shoplist,
        meta: { title: '图文素材' },
      },
    ]
  },
  {
    path: '/login',
    name: 'login',
    component: login,
    meta: { title: '登录' },
  }
];

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})




export default router
