
<template>
  <div>
    <router-view></router-view>
  </div>
</template>
<script>
export default {
  data() {
    return {
     
    };
  },

  mounted() {
    // this.$router.push("/");
  },
  methods: {
   
  },
};
</script>

<style lang="scss">

</style>
