
<template>
  <div>
    <img src="@/assets/viphead.png" class="headimg" alt="" />
    <div class="headbox">
      <div class="headtop">
        <img v-if="userData.avatar" :src="userData.avatar" alt="" />
        <img v-else src="@/assets/avatar.png" alt="" />
        <div class="editbtn" @click="editModal=true">编辑资料</div>
      </div>
      <div class="headbtm">
        <div class="headleft">
          <div style="display: flex;align-items: center;"><p>{{userInfo.nickname || userInfo.mobile}}</p>  <p style="margin-left: 40px;font-size: 16px;">邀请码:{{ userInfo.share_code || '暂无'}}</p></div>
         
         <div style="display: flex;align-items: center;">
          <span>预估收益</span><span class="bulespan">0</span>
          <span style="margin-left: 40px">总充值</span
          ><span class="bulespan">{{ userInfo.total_consume || 0 }}</span>
         </div>
        </div>
        <div class="headright">
          <div class="vip1box">
            <div class="vip1head">
              <span>我的佣金</span>
              <!-- <div class="ljtxbtn">立即提现</div> -->
            </div>
            <div class="vip1btm">{{userInfo.money || 0}}</div>
          </div>
          <div class="vip2box">
            <div class="vip1head">
              <span>我的会员</span>
              <div class="ljtxbtn" @click="showVipModal">立即充值</div>
            </div>
            <div class="vip1btm">
              <span>到期时间：{{userInfo.vip_times || '暂无'}}</span>
              <span>可用扣点：{{userInfo.score || 0}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="tablebox">
      <div class="ccc">
        <div class="aaaaa" style="background: none">
          <!-- <el-radio-group default-value="a" button-style="solid" size="large">
            <el-radio-button value="a"> 佣金明细 </el-radio-button>
            <el-radio-button value="b"> 账户明细</el-radio-button>
          </el-radio-group> -->
        </div>
        <div class="aaaaa">
            <el-date-picker
            v-model="date"
            @change="selectTime"
            type="daterange"
            value-format="yyyy-MM-dd"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期">
          </el-date-picker>
        </div>
      </div>
      <div class="acctablebox">
        <el-table
         
          :data="tableList"
          style="width: 100%; background-color: #ecf2f9"
          :row-class-name="tableRowClassName"
          :header-cell-style="{
            backgroundColor: '#ECF2F9',
            color: '#000',
            fontSize: '18px',
          }"
          @selection-change="handleSelectionChange"
        >
          <el-table-column prop="wxstatus" label="单号" min-width="180">
          </el-table-column>

          <el-table-column prop="wxstatus" label="时间" min-width="140">
          </el-table-column>
          <el-table-column prop="wxstatus" label="来源" min-width="240">
          </el-table-column>
          <el-table-column prop="wxstatus" label="金额" min-width="100">
          </el-table-column>
          <el-table-column prop="wxstatus" label="状态" min-width="100">
          </el-table-column>
        </el-table>
      </div>
      <div class="accbotm" v-if="tablist.length>0">
        <el-pagination background layout="prev, pager, next" :total="1000">
        </el-pagination>
      </div>

      <el-empty v-if="tablist.length<=0" :image="`${$IMG_URL}/imgs/empty/empty.png`" description="暂无数据" :image-size="500"></el-empty>
    </div>
   

    <!--支付弹窗 -->
    <el-dialog title="" :visible.sync="vipshow" width="800px" custom-class="dialog-box-cl">
      <div class="vipshowbox">
        <div class="vipshowhead" slot="title">
          <p>抠图换图年度特大力度活动</p>
          <div>
            <div>我的会员-到期时间:{{userInfo.vip_times || '暂无'}}</div>
            <span>可用扣点：{{userInfo.score || 0}}</span>
          </div>
        </div>
        <div class="vipshowbtm">
          <div class="vip1box">
            <div
              class="vip11"
              v-for="(item, index) in rechargeList"
              :key="index"
              :class="tabindex == index ? 'active' : ''"
              @click="tabchange(item, index)"
            >
              <span>{{ item.times }}天</span>
              <div style="display: flex; align-items: center">
                <p>{{ item.score }}</p>
                <span>点</span>
              </div>
              <span class="price">￥{{ item.money }}</span>
            </div>
          </div>
          <div class="codebox">
            <img v-if="qrcode" :src="qrcode" alt="" />
            <img v-else src="@/assets/a5.png" alt="" />
            <div class="qqqqq">
              <div class="code1">
                <span>支付金额: </span>
                <p>{{money}}</p>
              </div>
              <div class="code2">支付宝/微信扫描支付</div>
              <div class="code3">
                充值即视为阅读并同意<a>《充值协议》</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
    <!--支付弹窗 -->
    <!--编辑资料 -->
    <el-dialog title="" :visible.sync="editModal" width="800px" custom-class="dialog-box-cl">
      <div class="dialog-content-box">
          <div class="avatar-box">
            <img  class="ava-img" v-if="userData.avatar" :src="userData.avatar" alt="" />
            <img  class="ava-img" v-else src="@/assets/avatar.png" alt="" />
            
            <el-upload
              class="upload-demo"
              action="https://kt.breekj.top/addons/alioss/index/testInit"
              :limit="1"
              :show-file-list="false"
              :on-success="uploadImg"
            >
              <div class="ava-btn">更换头像</div>
            </el-upload>
          </div>
          <div class="form-box">
              <div class="form-box-item">
                <div class="item-t">昵称</div>
                <div class="input-box">
                  <input class="input-box-in" type="text" v-model="userData.nickname"  placeholder="请输入用户昵称"/>
                </div>
              </div>
              <div class="form-box-item">
                <div class="item-t">邮箱</div>
                <div class="input-box">
                  <input class="input-box-in" type="text" v-model="userData.email" placeholder="请输入邮箱"/>
                </div>
              </div>
          </div>
          <div class="form-box">
              <div class="form-box-item">
                <div class="item-t">生日</div>
                <div class="input-box">
                  <input class="input-box-in" type="text" v-model="userData.birthday" placeholder="请输入生日"/>
                </div>
              </div>
              <div class="form-box-item">
                <div class="item-t">手机号</div>
                <div class="input-box">
                  <input class="input-box-in" type="text" v-model="userData.mobile" placeholder="请输入绑定手机号"/>
                </div>
              </div>
          </div>
      </div>
  
      <div slot="footer" class="dialog-footer">
        <el-button class="save-btn" type="primary" @click="saveUserInfo">保 存</el-button>
      </div>
  </el-dialog>
    <!--编辑资料 -->

  </div>
</template>
  <script>
  import { mapGetters, mapActions } from 'vuex'
  import {profileApi} from '@/api/user.js';
export default {
  data() {
    return {
      tableList: [],
      tabindex: 0,
      vipshow: false,
      tablist: [],
      money:0,
      date:'',
      editModal:false,
      qrcode:'',
      fileList:[],
      userData:{
        nickname:'',
        avatar:'',
        mobile:'',
        email:'',
      },
    };
  },
  computed:{
    ...mapGetters(['user','userInfo']),
    rechargeList(){
      return this.$store.getters.recharge.moneys;
    },
  },
  mounted() {
    let storage = window.localStorage;
    if(!this.userInfo){
      this.userData=JSON.parse(JSON.stringify(storage.getItem('userInfo')));
    }else{
      this.userData=this.userInfo;
    }

  },
  methods: {
    handleSelectionChange() {},
    tableRowClassName({ row, rowIndex }) {
      return "tablerowcolor";
    },
    tabchange(item, index) {
      this.tabindex = index;
    },
    showVipModal(){
      this.money=this.rechargeList[0].money;
      this.vipshow=true;
    },
    selectTime(e){
      console.log(e);
    },
    uploadImg(file, fileList){
      console.log(file.data.fullurl);
      this.userData.avatar=file.data.fullurl;
    },
    saveUserInfo(){
      let that=this;
      if(!that.userData.nickname){
        that.$message.error('昵称不能为空');
      }
      profileApi({
        nickname:this.userData.nickname,
        avatar:this.userData.avatar,
        email:this.userData.email,
        birthday:this.userData.birthday
      }).then(res=>{
        that.$store
            .dispatch("user/getInfo", {
              that: this
            })
            .then(res => {
             that.editModal=false;
              
            });
      });

    }
  },
};
</script>
  
  <style scoped lang="scss">
input:focus {
    outline: none;
}
  .dialog-content-box{
    min-height: 500px;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    padding-bottom: 80px;
    .avatar-box{
      width: 400px;
      height: 140px;
      margin: 0 auto;
      margin-top: 60px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .ava-img{
        width: 116px;
        height: 116px;
        border-radius: 50%;
      }
      .ava-btn{
        width: 110px;
        height: 38px;
        line-height: 38px;
        background-color: #ECF2F9;
        color:#000000;
        font-size: 16px;
        text-align: center;
        border-radius: 8px;
        cursor: pointer;
        margin-top: 10px;
      }
      .upload-demo{
        display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      }
    }
    
    .form-box{
      display: flex;
      flex-wrap: wrap;
      margin: 48px 48px 0 48px;
      justify-content: space-around;
      .form-box-item{
        .item-t{
          font-size: 16px;
          font-weight: 600;
          color: #000000;
        }
        .input-box{
          margin-top: 10px;
          background-color: #ECF2F9;
          padding:0 8px;
          border-radius: 4px;
          .input-box-in{
            height: 48px;
            line-height: 48px;
            background-color: #ECF2F9;
            width: 300px;
            border: none;
            border-radius: 4px;
          }
          
        }
      }
    }
  }
 
  .save-btn{
      width: 350px;
      height: 48px;
    }

.headimg {
  width: 100%;
  height: 180px;
  background-color: #aaa;
}

.headbox {
  width: 100%;
  height: 210px;
  // background: #eee;
  margin-top: -40px;
  border-bottom: 1px solid #d8d8d8;
  .headtop {
    display: flex;
    align-items: center;
    padding: 0 30px;
    box-sizing: border-box;
    img {
      width: 80px;
      height: 80px;
      border-radius: 50%;
      border: 2px solid #00f;
      margin-right: 30px;
      box-shadow: 0 0 0 6px #fff;
    }
    .editbtn {
      width: 120px;
      height: 37px;
      background: #ffffff;
      border-radius: 19px;
      border: 1px solid #2758ff;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
  }
  .headbtm {
    padding: 0 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .headleft {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      p {
        font-weight: bold;
        font-size: 24px;
        color: #333333;
      }
      span {
        font-weight: 400;
        font-size: 18px;
        color: #666666;
      }
      .bulespan {
        font-weight: bold;
        font-size: 26px;
        color: #2758ff;
        margin-left: 8px;
      }
    }
    .headright {
      display: flex;
      align-items: center;
      .vip1box {
        width: 300px;
        height: 100px;
        border-radius: 8px;
        background: url("@/assets/vip2.png");
        background-size: cover;
        margin-right: 20px;
        padding: 16px;
      }

      .vip2box {
        width: 580px;
        height: 100px;
        padding: 16px;

        border-radius: 8px;
        background: url("@/assets/vip1.png");
        background-size: cover;
      }
    }
  }
}

.vip1head {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  span {
    font-size: 20px;
    color: #ffffff;
  }
  .ljtxbtn {
    width: 80px;
    height: 28px;
    background: #ffffff;
    border-radius: 14px;
    color: #2758ff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
  }
  .ljtxbtn:hover {
    cursor: pointer;
  }
}
.vip1btm {
  font-size: 32px;
  color: #ffffff;
  display: flex;
  justify-content: space-between;
  span {
    font-weight: 400;
    font-size: 17px;
    color: #ffffff;
    margin-top: 10px;
  }
}

.tablebox {
  width: 100%;
  // height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  flex-direction: column;
}

.ccc {
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .bbbbb {
    background: #ecf2f9;
    padding: 6px;
    border-radius: 40px;

    font-weight: 400;
    color: #696969;
    margin-right: 20px;
  }
  .bbbbb {
    padding: 6px 10px;
  }
}

.el-table .tablerowcolor {
  background: #ecf2f9;
  font-size: 24px;
}
.accbotm {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.acctablebox {
  width: 100%;
  // height: calc(100vh - 700px);
  margin-top: 10px;
  background-color: #ecf2f9;
  border-radius: 8px;
  padding: 10px;
}
.accconthead {
  width: 100%;
  height: 48px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  .bbbbb {
    background: #ecf2f9;
    padding: 6px;
    border-radius: 4px;

    font-weight: 400;
    color: #696969;
  }
  .bbbbb {
    padding: 6px 10px;
  }
}

.vipshowbox {
  width: 800px;
  height: 600px;
  background: #8c8c8c;
  border-radius: 20px;
  .vipshowbtm {
    width: 100%;
    height: 460px;
    background: #ecf2f9;
    padding: 20px;
    box-sizing: border-box;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;

    .vip1box {
      width: 100%;
      display: flex;
      height: 180px;
      justify-content: space-between;
      .vip11:hover {
        cursor: pointer;
      }
      .vip11 {
        width:180px;
        height: 180px;
        background: #ffffff;
        border-radius: 12px;
        border: 2px solid #ecf2f9;
        font-size: 18px;
        color: #666666;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        padding: 10px 0;

        p {
          font-size: 40px;
          color: #000000;
          margin-bottom: 10px;
          font-weight: bold;
        }
        .price {
          font-size: 28px;
          color: #2758ff;
          font-weight: bold;
        }
      }
      .active {
        border: 2px solid #2758ff;
      }
    }
  }
  .vipshowhead {
    width: 100%;
    height: 140px;
    background: url("@/assets/vipshowhead.png") no-repeat;
    background-size: 100% 100%;
    padding: 20px;
    box-sizing: border-box;
    p {
      font-size: 32px;
      color: #ffffff;
      margin-bottom: 10px;
    }
    & > div {
      display: flex;
      align-items: center;
      div {
        font-weight: 400;
        font-size: 20px;
        color: #ffffff;
      }
      span {
        font-weight: 400;
        font-size: 20px;
        color: #ffffff;
        margin-left: 30px;
      }
    }
  }
}

.codebox {
  width: 100%;
  height: 220px;
  background: #fff;
  border-radius: 20px;
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 190px;
    height: 190px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding:10px;
    background-color: #f8f8f8;
    margin-right: 30px;;
  }

  .qqqqq {
    .code1 {
      display: flex;
      align-items: center;
      font-weight: bold;
      font-size: 18px;
      color: #000000;

      p {
        margin-bottom: 0;
        font-weight: bold;
        font-size: 32px;
        color: #2758ff;
      }
    }
    .code2 {
      font-weight: 400;
      font-size: 15px;
      color: #000000;
    }
    .code3 {
      font-size: 13px;
      color: #666666;
      margin-top: 15px;
      span {
        color: #2758ff;
      }
    }
  }
}
</style>
  