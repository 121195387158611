import request from './request'
/**
 * @description 账号数据
 */
export function accountDataShopApi(){
    return request.get(`shop_account/accountData`);
  }
  
  /**
   * @description 账号列表
   */
  export function accountListShopApi(data){
    return request.get(`shop_account/accountlist`,data);
  }
  
  /**
   * 获取授权URL
   * @param {*} data 
   * @returns 
   */
  export function webUrlShopApi(data){
    return request.post(`shop_account/webUrl`,data);
  }
  

  
  /**
   * 通过code创建用户
   * @param {*} data 
   * @returns 
   */
  export function createAccountShopApi(data){
    return request.post(`shop_account/createAccount`,data);
  }