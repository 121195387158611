import { initApi } from "@/api/api";
import router from "@/router";
const state={
    initShop:{},//平台基础信息
    newNotice:{},//公告数据
    platformList:{},//平台数据
    recharge:{},//充值
}

const mutations={
    SET_INITSHOP: (state, initShop) => {
        state.initShop = initShop;
    },
    SET_NOTICE: (state, newNotice) => {
        state.newNotice = newNotice;
    },
    SET_PLATFORM: (state, platformList) => {
        state.platformList = platformList;
    },
    SET_RECHARGE:(state, recharge) => {
        state.recharge = recharge;
    },
}

const actions={
    initConfig({ commit }) {
        // const { username, password } = userInfo
        return new Promise((resolve, reject) => {
            initApi().then(response => {
              const { data } = response;
              commit("SET_INITSHOP", data.initShop);
              commit("SET_NOTICE", data.newNotice);
              commit("SET_PLATFORM", data.platformList);
              commit("SET_RECHARGE", data.recharge);
              resolve(data);
            })
            .catch(error => {
              reject(error);
            });
        });
      },
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
  };