
<template>
  <div>
    <div class="BOX">
      <div class="headbox">
        <div class="headtop">
          <div class="spxx">
            <div class="tzggtitle">
              <img class="tzggicon" src="@/assets/hld.png" alt="" />
              商品信息
            </div>
            <div class="aaaaaaaaaa" style="display: flex;">
              <el-input v-model="unique_id"  placeholder="请输入输入商品ID…"  :clearable="true"></el-input>
              <el-button type="primary" size="small" style="margin-left: 10px;" @click="selectGoods" :loading="loading">查询</el-button>
            </div>
            <div class="shopitem" v-if="goods.id>0">
              <img :src="goods.thumb_image" alt="" />
              <div class="itemright">
                <div class="itemtitle">{{goods.title}} </div>
                <div class="itemID">ID：{{goods.unique_id}}</div>
                <!-- <div class="itemdel" @click="delitem(item)">
                  <i class="el-icon-delete"></i>
                </div> -->
              </div>
            </div>
          </div>
          <div class="zdytp">
            <div class="tzggtitle">
              <img class="tzggicon" src="@/assets/hld.png" alt="" />
              自定义图片
            </div>
            <div class="zdytpuploadbox">
              <div class="zdytpupload">
                <el-upload
                  action="#"
                  list-type="picture-card"
                  :auto-upload="false"
                  :limit="3"
                  class="aaaaaaaaaaa"
                  style="display: flex;"
                >
                  <i slot="default" class="el-icon-plus"></i>
                  <div slot="file" slot-scope="{ file }" style="display: flex;">
                    <img
                      class="el-upload-list__item-thumbnail"
                      :src="file.url"
                      alt=""
                      style="width: 100px; height: 100px"  />
                    <span class="el-upload-list__item-actions">
                      <span
                        v-if="!disabled"
                        class="el-upload-list__item-delete"
                        @click="handleRemove(file)"
                      >
                        <i class="el-icon-delete"></i>
                      </span>
                    </span>
                  </div>
                </el-upload>
              </div>
              <div class="zdytptext">建议：jpg、png等格式，比例范围9:</div>
            </div>
          </div>
          <div class="yyfg">
            <div class="tzggtitle">
              <img class="tzggicon" src="@/assets/hld.png" alt="" />
              音乐风格
            </div>
            <div class="grid-container">
              <div
                v-for="(item, index) in items"
                :key="index"
                class="grid-item"
                :class="item.show == true ? 'selectitem' : ''"
                @click="selectitem(item, index)"
              >
                {{ item.content }}
              </div>
            </div>
          </div>
          <div class="gjmd">
            <div class="tzggtitle">
              <img class="tzggicon" src="@/assets/hld.png" alt="" />
              关键买点
            </div>
            <div>
              <el-input
                :autosize="{ minRows: 11, maxRows: 11 }"
                placeholder="请输入关键买点…"
                v-model="textarea2"
              >
              </el-input>
            </div>
            <div class="tzggtitle" style="margin-top: 20px;">
              <img class="tzggicon" src="@/assets/hld.png" alt="" />
              视频类型
            </div>
            <div>

            </div>

          </div>
        </div>
        <div class="startbtn">开始生成(8点)</div>
      </div>
      <div class="botmbox">
        <div class="swiper">
          <el-carousel height="527px">
            <el-carousel-item v-for="item in 4" :key="item">
              <h3 class="small">{{ item }}</h3>
            </el-carousel-item>
          </el-carousel>
        </div>
        <div class="botmrightbox">
          <div class="tpbox">
            <div class="tptitle">
              <div class="TITLE">
                <img class="tzggicon" src="@/assets/hld.png" alt="" />
                图片
                <span> / 系统推荐顺序：锁定关注-展示细</span>
              </div>
              <div class="blue3btn">
                <div>
                  <img class="tzggicon" src="@/assets/cxsc.png" alt="" />
                  重新生成
                </div>
                <div>
                  <img class="tzggicon" src="@/assets/zdymb.png" alt="" />
                  自定义模板
                </div>
                <div>
                  <img class="tzggicon" src="@/assets/ghsx.png" alt="" />
                  更换顺序
                </div>
              </div>
            </div>
            <div class="tpimg3">
              <div class="div3"></div>
              <div class="div3"></div>
              <div class="div3"></div>
            </div>
          </div>
          <div class="tpbox">
            <div class="tptitle">
              <div class="TITLE">
                <img class="tzggicon" src="@/assets/hld.png" alt="" />
                文案
              </div>
              <div class="blue3btn">
                <div>
                  <img class="tzggicon" src="@/assets/cxsc.png" alt="" />
                  重新生成
                </div>
                <div>
                  <img class="tzggicon" src="@/assets/edit.png" alt="" />
                  编辑
                </div>
                <div>
                  <img class="tzggicon" src="@/assets/bfyp.png" alt="" />
                  播放音频
                </div>
                <div>
                  <el-switch v-model="waswitchval" active-text="开启口播">
                  </el-switch>
                </div>
              </div>
            </div>
            <div class="tpimg3">
              <!-- <a-textarea
                placeholder="请输入文案"
                style="background-color: #f4f4f4"
                :rows="4"
              /> -->
              <el-input type="textarea" :rows="7" placeholder="请输入文案">
              </el-input>
            </div>
          </div>
          <div class="tpbox">
            <div class="tptitle">
              <div class="TITLE">
                <img class="tzggicon" src="@/assets/hld.png" alt="" />
                音乐
              </div>
              <div class="blue3btn">
                <div>
                  <img class="tzggicon" src="@/assets/cxsc.png" alt="" />
                  换一首
                </div>
              </div>
            </div>
            <div class="tpimg3">
              <div class="audioitem">
                <img src="@/assets/cxsc.png" alt="" />
                <div class="itemright">
                  <div class="itemtitle">姑娘别哭泣</div>
                  <div class="itemID">野哥 4:00</div>
                  <div class="itemdel" @click="delitem(item)">
                    <i class="el-icon-delete"></i>删除
                  </div>
                </div>
              </div>
              <div class="audioitem">
                <img src="@/assets/cxsc.png" alt="" />
                <div class="itemright">
                  <div class="itemtitle">姑娘别哭泣</div>
                  <div class="itemID">野哥 4:00</div>
                  <div class="itemdel" @click="delitem(item)">
                    <i class="el-icon-delete"></i>删除
                  </div>
                </div>
              </div>
              <div class="audioitem">
                <img src="@/assets/cxsc.png" alt="" />
                <div class="itemright">
                  <div class="itemtitle">姑娘别哭泣</div>
                  <div class="itemID">野哥 4:00</div>
                  <div class="itemdel" @click="delitem(item)">
                    <i class="el-icon-delete"></i>删除
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style="width: 100%; height: 108px"></div>
    </div>
    <div class="botmbtnbox">
      <div class="bigbtn">
        <img src="@/assets/ks.png" alt="" />
        快手授权发布
      </div>
      <div class="bigbtn" style="background: #101116">
        <img src="@/assets/dy.png" alt="" />
        抖音授权发布
      </div>
    </div>

    <!-- <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
      <img alt="example" style="width: 100%" :src="previewImage" />
    </a-modal> -->

    <el-dialog
      :center="true"
      :show-close="false"
      :visible.sync="previewVisible"
      :width="'800px'"
      ><img alt="example" style="width: 100%" :src="previewImage"
    /></el-dialog>
  </div>
</template>
  <script>
function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}
import { goodsDetailApi } from "@/api/api.js";
export default {
  data() {
    return {
      dialogImageUrl: "",
      dialogVisible: false,
      disabled: false,
      waswitchval: false,
      textarea2: "",
      items: [
        { content: "Item 1", show: false },
        { content: "Item 2", show: false },
        { content: "Item 3", show: false },
        { content: "Item 4", show: false },
        { content: "Item 5", show: false },
        { content: "Item 6", show: false },
        { content: "Item 7", show: false },
        { content: "Item 8", show: false },
      ],
      previewVisible: false,
      previewImage: "",
      fileList: [
        {
          uid: "-1",
          name: "image.png",
          status: "done",
          url: "https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png",
        },
        {
          uid: "-2",
          name: "image.png",
          status: "done",
          url: "https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png",
        },
        {
          uid: "-3",
          name: "image.png",
          status: "done",
          url: "https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png",
        },
      ],
      input: "",
      goods:[],
      loading:false,
      unique_id:'3679401781437268337'
    };
  },
  mounted() {},
  methods: {
    selectGoods(){
      let that=this;
      that.loading=true;
      goodsDetailApi({unique_id:that.unique_id}).then(res=>{
        console.log(res);
        that.goods=res.data;
        that.loading=false;
      });
    },
    handleRemove(file) {
      console.log(file);
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    handleDownload(file) {
      console.log(file);
    },
    handleCancel() {
      this.previewVisible = false;
    },
    selectitem(item, index) {
      // console.log(item,index)
      this.items[index].show = !item.show;
    },
    async handlePreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj);
      }
      this.previewImage = file.url || file.preview;
      this.previewVisible = true;
    },
    handleChange({ fileList }) {
      this.fileList = fileList;
    },
  },
};
</script>
  
  <style scoped lang="scss">
  :deep(.el-upload-list--picture-card){
    display: flex !important;
  }
.BOX {
  width: 100%;
  height: calc(100vh - 100px);
  padding: 20px;
  background: #e1eaf2;
  z-index: 1;
  overflow-x: hidden;
  div {
    z-index: 1;
  }

  .botmbox {
    width: 100%;
    display: flex;
    margin-top: 20px;
    .swiper {
      width: 336px;
      height: 567px;
      background: #ffffff;
      border-radius: 12px;
      padding: 20px;
      box-sizing: border-box;
    }

    .botmrightbox {
      flex: 1;
      padding-left: 20px;
      .tpbox {
        width: 100%;
        // height: 240px;
        border-radius: 12px;
        background: #fff;
        padding: 20px;
        box-sizing: border-box;
        margin-bottom: 20px;

        .tpimg3 {
          width: 100%;
          min-height: 150px;
          margin-top: 10px;
          display: flex;
          flex-wrap: wrap;
          .div3 {
            width: 106px;
            height: 149px;
            background: #aaa;
            margin: 0 10px;
          }

          ::v-deep .el-textarea__inner {
            background-color: #f4f4f4;
          }
        }

        .tptitle {
          display: flex;
          align-items: center;
          justify-content: space-between;
          .TITLE {
            font-size: 24px;
            color: #000;
            display: flex;
            align-items: center;
            span {
              font-size: 18px;
              color: #aaa;
            }
          }

          .blue3btn {
            font-weight: 400;
            font-size: 20px;
            color: #2758ff;
            display: flex;
            align-items: center;
            div {
              display: flex;
              align-items: center;
              margin: 0 14px;
              img {
                width: 19px;
                height: 20px;
              }
            }

            div:hover {
              cursor: pointer;
            }
          }
        }
      }
    }
  }
  .headbox {
    width: 100%;
    height: 400px;
    background: #fff;
    border-radius: 10px;
    padding: 20px;
    box-sizing: border-box;

    .startbtn {
      width: 360px;
      height: 60px;
      background: #2758ff;
      border-radius: 8px;
      font-weight: 400;
      font-size: 18px;
      color: #ffffff;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 30px auto;
    }

    .startbtn:hover {
      cursor: pointer;
    }

    .headtop {
      width: 100%;
      height: 260px;
      display: flex;
      // justify-content: space-between;
      & > div {
        margin-right: 20px;
      }
      .spxx {
        width: 31%;
        min-width: 420px;
        height: 100%;
        display: flex;
        flex-direction: column;
        // justify-content: space-between;
      }

      .zdytp {
        width: 27%;
        min-width: 360px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        .zdytpuploadbox {
          width: 100%;
          height: 190px;

          background: #f4f4f4;

          .zdytpupload {
            width: 100%;
            height: 130px;
            padding: 12px;
            box-sizing: border-box;
          }
          .zdytptext {
            font-weight: 400;
            font-size: 14px;
            color: #666666;
            padding: 12px;
            box-sizing: border-box;
          }
        }
      }

      .yyfg {
        width: 230px;
        min-width: 230px;
        height: 100%;
      }

      .gjmd {
        flex: 1;
        min-width: 300px;

        height: 100%;

        div {
          ::v-deep .el-textarea__inner {
            background-color: #f4f4f4;
          }
        }
      }
    }
  }
}

.tzggtitle {
  font-size: 20px;
  color: #000;
  display: flex;
  align-items: center;
  margin: 10px 0;
}

.shopitem {
  width: 100%;
  height: 144px;
  background: #f4f4f4;
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding: 12px;
  box-sizing: border-box;
  margin-top: 20px;

  img {
    width: 124px;
    height: 124px;
    border-radius: 8px;
  }
  .itemright {
    flex: 1;
    height: 90%;
    padding: 0 10px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .itemtitle {
      font-weight: 400;
      font-size: 16px;
      color: #000000;
    }
    .itemID {
      font-weight: 400;
      font-size: 18px;
      color: #666666;
    }
    .itemdel {
      font-weight: 400;
      font-size: 16px;
      color: #2758ff;
      text-align: right;
    }
    .itemdel:hover {
      cursor: pointer;
    }
  }
}
.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}
.ant-upload-picture-card-wrapper {
  display: flex;
  justify-content: space-around;
}

.grid-container {
  display: flex;
  flex-wrap: wrap; /* 允许子元素换行 */
  margin: -2px 0 0 -8px; /* 调整上边距和左边距以消除顶部和左侧间隔 */
}

.grid-item {
  width: 108px;
  height: 40px;
  margin: 12px 0 0 8px; /* 上下间距12px，左右间距8px（右边距已包含在容器负边距中）*/
  background-color: #f0f0f0; /* 仅为示例添加背景色 */
  display: flex; /* 如果需要在item中居中内容，可以启用flex */
  align-items: center; /* 垂直居中 */
  justify-content: center; /* 水平居中 */

  width: 108px;
  height: 40px;
  background: #f4f4f4;
  border-radius: 4px;
}
.grid-item:hover {
  cursor: pointer;
}
.selectitem {
  width: 108px;
  height: 40px;
  background: rgba(39, 88, 255, 0.12);
  border-radius: 4px;
  border: 1px solid #2f5efe;
}

.audioitem {
  width: 370px;
  height: 124px;
  background: #f4f4f4;
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding: 12px;
  box-sizing: border-box;
  margin-right: 20px;
  margin-bottom: 20px;

  img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
  }
  .itemright {
    flex: 1;
    height: 90%;
    padding: 0 10px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .itemtitle {
      font-weight: 400;
      font-size: 20px;
      color: #000000;
    }
    .itemID {
      font-weight: 400;
      font-size: 18px;
      color: #666666;
    }
    .itemdel {
      font-weight: 400;
      font-size: 16px;
      color: #2758ff;
      text-align: right;
    }
    .itemdel:hover {
      cursor: pointer;
    }
  }
}
.botmbtnbox {
  width: 100%;
  height: 108px;
  background: #ffffff;
  box-shadow: 0px 0px 12px 0px rgba(43, 108, 143, 0.1);
  margin-top: -108px;
  z-index: 88 !important;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  .bigbtn:hover {
    cursor: pointer;
  }
  .bigbtn {
    width: 240px;
    height: 60px;
    background: #fe8d31;

    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    margin: 0 10px;
    font-weight: 400;
    font-size: 20px;
    color: #ffffff;
    img {
      width: 20px;
      margin: 0 6px;
      height: 24px;
    }
  }
}

.tzggicon {
  width: 6px;
  height: 22px;
  margin-right: 4px;
}
.aaaaaaaaaa {
  padding: 0 10px;
  ::v-deep .el-input__inner {
    background: #f4f4f4;
    border: none;
  }
}
.aaaaaaaaaaa {
  display: flex ;
  ::v-deep .el-upload--picture-card {
    width: 110px;
    height: 110px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
  }
  
  ::v-deep  .el-upload-list__item {
    width: 110px;
    height: 110px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
  