
const getters = {
  
  token: state => state.user.token,
  avatar: state => state.user.avatar,
  name: state => state.user.name,
  userInfo: state => state.user.userinfo,
  initShop: state => state.config.initShop,
  newNotice: state => state.config.newNotice,
  platformList: state => state.config.platformList,
  recharge: state => state.config.recharge,
  
}
export default getters
